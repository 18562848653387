.project-grid {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.project-grid-max-2 {
    max-width: 800px;
}

.project-grid-max-3 {
    max-width: 1200px;
}

.project-grid-max-4 {
    max-width: 1500px;
}

.project-grid-item {
    max-width: 400px;
    min-width: 300px;
    flex: 301px; /* item will wrap when reaching 301px */
    margin-bottom: 40px;
}