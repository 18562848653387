.logohome {
	width: 50%;
	max-width: 300px;
	margin: 0 auto;
	margin-top: 50px;
}

.baseline {
	font-family: "Courier New", Courier, monospace;
	font-size: 16pt;
	font-weight: normal;
	margin-top: 50px;
	margin-left: 10%;
	margin-right: 10%;
	text-align: center;
	color: #444;
} 

/* mobile ou tablette */
@media all and (max-width: 768px) {
	.baseline {
		font-size: 12pt;
	}
	#uiview section {min-height: 100vh;}
	.content {padding-top:50px;}
	.logohome {
		margin-top: 100px;
		width: 50%;
	}
}

@-webkit-keyframes spaceship-move {
	0% {transform: translateY(0px) translateX(0px);}
	50% {transform: translateY(-15px) translateX(15px);}
	100% {transform: translateY(0px) translateX(0px);}
}

@keyframes spaceship-move {
	0% {transform: translateY(0px) translateX(0px);}
	50% {transform: translateY(-20px) translateX(20px);}
	100% {transform: translateY(0px) translateX(0px);}
}

.main.ng-enter {
	position: absolute;
	top: -100vh;
	transition: all ease 500ms;
}

.main.ng-enter-active {
	position: relative;
	top: 0px;
	transition: all ease 500ms;
}

.spaceshipmove {
	animation: spaceship-move 1s ease-in-out infinite;
}

/*mobile*/
.scrollToTop {
	position: fixed;
	bottom: 3%;
	z-index: 1; 
	cursor: pointer;
	display: block;
	color: grey;
	right: -100px;
	transition: right 0.9s ease; 
}

.scrollToTop.visible {
	right: 25px;
	
}

/*laptops, desktop*/
@media all and (min-width: 769px) {
	.scrollToTop {
		bottom: 15%;
	}
	.scrollToTop.visible {
		bottom: 15%;
	}
}

.iconMargin {
	margin-left: 5px;
}