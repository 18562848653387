/*
* @Author: axeldelcros
* @Date:   2017-01-21 14:35:41
* @Last Modified by:   axeldelcros
* @Last Modified time: 2017-02-09 22:01:03
*/
#toplogo {
	float: left;
	width: 200px;
	height: 69px;
	margin-top: 10px;
	margin-left: 50px;
	position: fixed;
}

.logo-top-mobile {
	display: none;
	background-image: url('../img/top-logo.svg');
	width: 50px;
	height: 50px;
	background-repeat: no-repeat;
	background-size: 100%;
	float: left;
	margin-left: calc(50% - 22.5px);
}

.navbarmenu {
	margin-right: 50px;
}

.navbarmenu ul {
	display: table-row;
	margin-right: 10%;
	height: 80px;
}

.navbarmenu ul li {
	display: table-cell;
	vertical-align: middle;
	margin: 10px;
	height: 60px;
	padding: 0 10px 5px 10px;
}

.navbarmenu ul li a {
	display: table-cell;
	height: 50px;
	vertical-align: middle;
	font-size: 18px;
	color:#555;
	text-decoration: none;
	text-align: center;
	max-width: 130px;
	padding-bottom: 5px;
	padding-top: 10px;
	border-bottom: 2px solid rgba(130, 130, 130, 0);
	transition: all 500ms;
}

.navbarmenu ul li a:hover {
	border-bottom: 2px solid rgba(130, 130, 130, 1);
	transition: all 500ms;
	cursor: pointer;
}

.navbarmenu ul li a.selected {
	border-bottom: 2px solid rgba(130, 130, 130, 1);
}

.nav-button {
	position: fixed;
	width:50px;
	height:50px;
	right: 50px;
	top: 25px;
	display: none;
	z-index: inherit;
}
.nav-button>span {
	border-radius: 10%;
	position: absolute;
	top:50%;
	left: 50%;
	display: block;
	width:30px;
	height:4px;
	background-color:#555;
	transform: translateX(-50%) translateY(-50%);
}
.nav-button>span:after, .nav-button>span:before {
	border-radius: 10%;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 30px;
	height: 4px;
	background-color: #555;
	transition:transform 0.5s ;
}
.nav-button>span:before {transform: translateY(-10px);}
.nav-button>span:after {transform: translateY(10px);}
.nav-button:hover {cursor: pointer;}
.nav-button:hover>span:before {transform: translateY(-12px);}
.nav-button:hover>span:after {transform: translateY(12px);}
.nav-button {transition: transform 0.5s;}
.nav-button.is-open {transform: rotate(180deg);}
.nav-button.is-open>span {background-color: transparent;}
.nav-button.is-open>span:before {transform: translateY(0) rotate(45deg);}
.nav-button.is-open>span:after {transform: translateY(0) rotate(-45deg);}
.nav-button.is-open>span:before,.nav-button.is-open>span:after {transform-origin:50% 50%;}
.nav-button.is-open>span {transition: background-color 0.5s;}
.nav-menu-mobile {
	display:none;
	position: fixed;
	max-height: calc(100% - 50px);
	opacity:0;
	margin-top: 50px;
	transition: all ease-in-out 300ms;
	background-color: #efefef;
}

.infolegales {
	font-size: 10pt;
	text-align: center;
}

.movaicode {
	color: black;
}

.visible{
	opacity: 1;
	transition: opacity 0.3s linear;
}

.hidden{
	opacity: 0;
	transition: opacity 0.3s linear;
}

@media all and (max-width: 768px) {
	.logo-top-mobile {display: block;}
	.logo-top-mobile.show{
		opacity: 1;
		transition: opacity 0.3s linear;
	}
	.logo-top-mobile.hide{
		opacity: 0;
		transition: opacity 0.3s linear;
	}
	.nav-button {
		display:block;
		right: 25px;
		top: 5px;
	}
	#toplogolink {display: none;}
}

@media all and (max-width: 769px) {
	.nav-button {display:block;}
	.navbarmenu {display: none;}
	header {
		height: 50px;
		z-index:999999;
		text-align: center;
		font-size: 20pt;
	}
	header ul.socialnetwork {
		height: 62px;
	}
	header ul.socialnetwork li {
		width:15%;
		display: inline-block;
	}
	header ul.socialnetwork li a {
		font-size: 27pt;
		text-shadow: 1px 4px 6px #FEFEFE, 0 0 0 #000, 1px 4px 6px #FEFEFE;
		color:rgba(100,100,100, 0.8);
	}
	li {margin-left:0;}
	.nav-menu-mobile.is-open {
		display: block;
		opacity: 1;
		transition: all ease-in-out 300ms;
		width: 100%;
		height: 100%;
		/* Les proprietes suivantes sont pour le scroll dans le menu*/
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
	.nav-menu-mobile.is-open li {margin-bottom: 10px;}
	.nav-menu-mobile.is-open a.selected {
		border-bottom:2px solid rgba(130, 130, 130, 1);	
		margin-bottom:10px;
	}

	#ui-view section {min-height: 100vh;}
	#ui-view section#home {height: 80vh;min-height: 80vh;}
}
