@-webkit-keyframes axemove-anim {
	0% {-webkit-transform: rotate(-1deg);transform:rotate(-1deg);}
	50% {-webkit-transform: rotate(20deg);transform:rotate(20deg);}
	100% {-webkit-transform: rotate(-1deg);transform:rotate(-1deg);}
}

@keyframes axemove-anim {
	0% {-webkit-transform: rotate(-1deg);transform:rotate(-1deg);}
	50% {-webkit-transform: rotate(20deg);transform:rotate(20deg);}
	100% {-webkit-transform: rotate(-1deg);transform:rotate(-1deg);}
}

@-webkit-keyframes wrenchmove-anim {
	0% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
	50% {-webkit-transform: rotate(7deg);transform:rotate(7deg);}
	100% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
}

@keyframes wrenchmove-anim {
	0% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
	50% {-webkit-transform: rotate(7deg);transform:rotate(7deg);}
	100% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
}

@-webkit-keyframes sawmove-anim {
	0% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
	50% {-webkit-transform: rotate(4deg);transform:rotate(4deg);}
	100% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
}

@keyframes sawmove-anim {
	0% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
	50% {-webkit-transform: rotate(4deg);transform:rotate(4deg);}
	100% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
}

@-webkit-keyframes cloudmove-anim {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px);}
	50% {-webkit-transform: translateY(6px);transform: translateY(6px);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@keyframes cloudmove-anim {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px);}
	50% {-webkit-transform: translateY(6px);transform: translateY(6px);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@-webkit-keyframes cloudarrowmove-anim {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px);}
	50% {-webkit-transform: translateY(12px);transform: translateY(12px);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@keyframes cloudarrowmove-anim {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px);}
	50% {-webkit-transform: translateY(12px);transform: translateY(12px);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@-webkit-keyframes webdevtextmove-anim {
	0% {-webkit-transform: translateY(-8px);transform: translateY(-8px);}
	50% {-webkit-transform: translateY(8px);transform: translateY(8px);}
	100% {-webkit-transform: translateY(-8px);transform: translateY(-8px);}
}

@keyframes webdevtextmove-anim {
	0% {-webkit-transform: translateY(-8px);transform: translateY(-8px);}
	50% {-webkit-transform: translateY(8px);transform: translateY(8px);}
	100% {-webkit-transform: translateY(-8px);transform: translateY(-8px);}
}

@-webkit-keyframes lockerupmove-anim {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px);}
	50% {-webkit-transform: translateY(-8px);transform: translateY(-8px);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@keyframes lockerupmove-anim {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px);}
	50% {-webkit-transform: translateY(-8px);transform: translateY(-8px);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@-webkit-keyframes lockerbodymove-anim {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px);}
	50% {-webkit-transform: translateY(5px);transform: translateY(5px);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@keyframes lockerbodymove-anim {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px);}
	50% {-webkit-transform: translateY(5px);transform: translateY(5px);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);}
}

@-webkit-keyframes giftmove-anim {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px);-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	50% {-webkit-transform: translateY(-12px);transform: translateY(-12px);-webkit-transform: rotate(-2deg);transform:rotate(-2deg);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);-webkit-transform: rotate(0deg);transform:rotate(0deg);}
}

@keyframes giftmove-anim {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px);-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	50% {-webkit-transform: translateY(-12px);transform: translateY(-12px);-webkit-transform: rotate(-2deg);transform:rotate(-2deg);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);-webkit-transform: rotate(0deg);transform:rotate(0deg);}
}

@-webkit-keyframes handmove-anim {
	0% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	50% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
	100% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
}

@keyframes handmove-anim {
	0% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	50% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
	100% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
}

@-webkit-keyframes manchemove-anim {
	0% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	50% {-webkit-transform: rotate(-2deg);transform:rotate(-2deg);}
	100% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
}

@keyframes manchemove-anim {
	0% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	50% {-webkit-transform: rotate(-2deg);transform:rotate(-2deg);}
	100% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
}

@-webkit-keyframes lwheelmove-anim {
	0% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	100% {-webkit-transform: rotate(360deg);transform:rotate(360deg);}
}

@keyframes lwheelmove-anim {
	0% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	100% {-webkit-transform: rotate(360deg);transform:rotate(360deg);}
}

@-webkit-keyframes smwheelmove-anim {
	0% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	100% {-webkit-transform: rotate(360deg);transform:rotate(360deg);}
}

@keyframes smwheelmove-anim {
	0% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	100% {-webkit-transform: rotate(-360deg);transform:rotate(-360deg);}
}

@-webkit-keyframes rightarmmove-anim {
	0% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	50% {-webkit-transform: rotate(-20deg);transform:rotate(-20deg);}
	100% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
}

@keyframes rightarmmove-anim {
	0% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	50% {-webkit-transform: rotate(20deg);transform:rotate(-20deg);}
	100% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
}

@-webkit-keyframes leftarmmove-anim {
	0% {-webkit-transform: rotate(-13deg);transform:rotate(-13deg);}
	50% {-webkit-transform: rotate(25deg);transform:rotate(25deg);}
	100% {-webkit-transform: rotate(-13deg);transform:rotate(-13deg);}
}

@keyframes leftarmmove-anim {
	0% {-webkit-transform: rotate(-13deg);transform:rotate(-13deg);}
	50% {-webkit-transform: rotate(25deg);transform:rotate(25deg);}
	100% {-webkit-transform: rotate(-13deg);transform:rotate(-13deg);}
}

@-webkit-keyframes infomove-anim {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px);-webkit-transform: rotate(0deg);transform:rotate(0deg);}
	50% {-webkit-transform: translateY(-12px);transform: translateY(-12px);-webkit-transform: rotate(-2deg);transform:rotate(-2deg);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);-webkit-transform: rotate(0deg);transform:rotate(0deg);}
}

@keyframes infomove-anim {
	0% {-webkit-transform: translateY(0px) translateX(-5px);transform: translateY(0px) translateX(-5px);}
	50% {-webkit-transform: translateY(-15px) translateX(4px);transform: translateY(-15px) translateX(4px);}
	100% {-webkit-transform: translateY(0px) translateX(-5px);transform: translateY(0px) translateX(-5px);}
}

@-webkit-keyframes stickmove-anim {
	0% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
	50% {-webkit-transform: rotate(4deg);transform:rotate(4deg);}
	100% {-webkit-transform: rotate(-3deg);transform:rotate(-3deg);}
}

@keyframes stickmove-anim {
	0% {-webkit-transform: rotate(-10deg);transform:rotate(-10deg);}
	50% {-webkit-transform: rotate(25deg);transform:rotate(25deg);}
	100% {-webkit-transform: rotate(-10deg);transform:rotate(-10deg);}
}

@-webkit-keyframes parkourmove-anim {
	0% {-webkit-transform: rotate(-1deg);transform:rotate(-1deg);}
	50% {-webkit-transform: rotate(5deg);transform:rotate(5deg);}
	100% {-webkit-transform: rotate(-1deg);transform:rotate(-1deg);}
}

@keyframes parkourmove-anim {
	0% {-webkit-transform: rotate(-1deg);transform:rotate(-1deg);}
	50% {-webkit-transform: rotate(5deg);transform:rotate(5deg);}
	100% {-webkit-transform: rotate(-1deg);transform:rotate(-1deg);}
}

@-webkit-keyframes saintleomove-anim {
	0% {-webkit-transform:  translateX(0px);transform: translateX(0px);}
	49.9% {-webkit-transform:  translateX(100%);transform: translateX(100%);}
	50% {-webkit-transform:  translateX(-100%);transform: translateX(-100%);}
	100% {-webkit-transform:  translateX(0px);transform: translateX(0px);}
}

@keyframes saintleomove-anim {
	0% {-webkit-transform:  translateX(0px);transform: translateX(0px);}
	49.9% {-webkit-transform:  translateX(100%);transform: translateX(100%);}
	50% {-webkit-transform:  translateX(-100%);transform: translateX(-100%);}
	100% {-webkit-transform:  translateX(0px);transform: translateX(0px);}
}

.axe{-webkit-transform: rotate(0deg);transform:rotate(0deg);-webkit-transform-origin-y: 40px;-webkit-transform-origin-x: 233px;transform-origin: 233px 40px;}
.axemove{animation: axemove-anim 1.5s ease-in-out infinite;}
.wrench{-webkit-transform: rotate(0deg);transform:rotate(0deg);-webkit-transform-origin-x: 169px;-webkit-transform-origin-y: 38px;transform-origin: 169px 38px;}
.wrenchmove{animation: wrenchmove-anim 1.3s ease-in-out infinite;}
.saw{-webkit-transform: rotate(0deg);transform:rotate(0deg);-webkit-transform-origin-x: 73px;-webkit-transform-origin-y: 40px;transform-origin: 73px 40px;}
.sawmove{animation: sawmove-anim 1.5s ease-in-out infinite;}
.cloud{-webkit-transform: translateY(0px);transform: translateY(0px);}
.cloudmove{animation: cloudmove-anim 1s ease-in-out infinite;}
.cloudarrow{-webkit-transform: translateY(0px);transform: translateY(0px);}
.cloudarrowmove{animation: cloudarrowmove-anim 1s ease-in-out infinite;}
.webdevtext{-webkit-transform: translateY(0px);transform: translateY(0px);}
.webdevtextmove{animation: webdevtextmove-anim 1.2s ease-in-out infinite;}
.lockerup{-webkit-transform: translateY(0px);transform: translateY(0px);}
.lockerupmove{animation: lockerupmove-anim 1s ease-in-out infinite;}
.lockerbody{-webkit-transform: translateY(0px);transform: translateY(0px);}
.lockerbodymove{animation: lockerbodymove-anim 1s ease-in-out infinite;}
.hand{-webkit-transform: rotate(0deg);transform:rotate(0deg);}
.handmove{animation: handmove-anim 1s ease-in-out infinite;}
.gift{-webkit-transform: translateY(0px);transform: translateY(0px);}
.giftmove{animation: giftmove-anim 1s ease-in-out infinite;}
.manche{-webkit-transform: rotate(0deg);transform:rotate(0deg);}
.manchemove{animation: manchemove-anim 1s ease-in-out infinite;}
.smwheel{-webkit-transform: rotate(0deg);transform:rotate(0deg);-webkit-transform-origin-x: 96px;-webkit-transform-origin-y: 107px;transform-origin: 96px 107px;}
.lwheel{-webkit-transform: rotate(0deg);transform:rotate(0deg);-webkit-transform-origin-x: 167.5px;-webkit-transform-origin-y: 77.2px;transform-origin: 167.5px 77.2px;}
.smwheelmove{animation: smwheelmove-anim 2s linear infinite;}
.lwheelmove{animation: lwheelmove-anim 2s linear infinite;}
.rightarm{-webkit-transform: rotate(0deg);transform:rotate(0deg);-webkit-transform-origin-x: 250px;-webkit-transform-origin-y: 86px;transform-origin: 250px 86px;}
.rightarmmove{animation: rightarmmove-anim 1s linear infinite;}
.leftarm{-webkit-transform: rotate(0deg);transform:rotate(0deg);-webkit-transform-origin-x: 49px;-webkit-transform-origin-y: 86px;transform-origin: 49px 86px;}
.leftarmmove{animation: leftarmmove-anim 0.8s ease-in-out infinite;}
.infomove{animation: infomove-anim 1s ease-in-out infinite;}
.stick{-webkit-transform: rotate(0deg);transform:rotate(0deg);-webkit-transform-origin-x: 240px; -webkit-transform-origin-y: 133px;transform-origin: 240px 133px;}
.stickmove{animation: stickmove-anim 1.5s ease-in-out infinite;}
.parkour{-webkit-transform: rotate(0deg);transform:rotate(0deg);-webkit-transform-origin-x: 417px; -webkit-transform-origin-y: 439px;transform-origin: 417px 439px;}
.parkourmove{animation: parkourmove-anim 1.5s ease-in-out infinite;}
.saintleo{-webkit-transform: rotate(0deg);transform:rotate(0deg);}
.saintleomove{animation: saintleomove-anim 1.5s ease-in-out infinite;}