.temoignage {
    padding: 0 20px;
    margin: 70px 0;
    display: flex;
    position: relative;
    align-items: center;
    row-gap: 40px;
    column-gap: 60px;
}

.temoignage-content {
    font-size: 1em;
    box-sizing: border-box;
}

.temoignage-title {
    text-align: center;
}

.temoignage-subtitle {
    font-size: 1.3em;
}

.temoignage-img { 
    display: flex;
    align-items: center;
    justify-content: center;
}

.temoignage li {
    list-style: circle;
    list-style-position: inside;
}

.reversed {
    flex-direction: row-reverse;
}

@media(max-width: 991px){
    .temoignage{
        flex-direction: column;
    }
    .temoignage-img { 
        width: 80%;
    }
}
