.bordure{
    height: 200px;
    width: 200px;
    border-radius: 100%;
}
.image{
    height: 100%;
    width: 100%;
}

.infoBoss{
    width:1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.HpBarre{
    width:400px;
    height:20px;
    -webkit-appearance: none;
    appearance: none;
}

.bossName{
    font-size: 25px;
    font-family: "rounded-grotesk";
    /* color:rgb(51,51,51); */
    color:silver;
    font-weight: none;
    margin:0;
}

