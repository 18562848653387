.red {
    color: #cd4442;
}

.blue {
    color: #1686b8;
}

.gris {
    color: silver;
}

.black {
    color: #333;
}

.quit {
    display: flex;
    justify-content: center;
    align-items: center;
}
