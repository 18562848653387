
.allPage{
    position: absolute;
	top: 0px;
    left: 0px;
	width: 100vw;
    height: 100vh;
    padding:0;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.board{
    height: 720px;
    width: 1000px;
    border:solid 1px black;
    position: relative;
    background: white;
}

.rect{
    height: 10px;
    border: solid green;
    border-radius: 15px;
    z-index: 3;
    background-color: green;
}



.ball{
    width:15px;
    height: 15px;
    border-radius: 100%;
    border: solid;
    z-index: 5;
}

.brick{
    width:60px;
    height:20px;
    border:solid black;
    z-index: 2;
    background-color: brown;
}

.global{
    height: 100%;
    width: 100%;
    z-index: 2;
}

.viseur{
    border-left:dotted;
    z-index: 0;
}

.bonus{
    height: 15px;
    width: 15px;
    border: solid;
    z-index: 0;
}