/*
* @Author: axeldelcros
* @Date:   2017-01-11 23:09:55
* @Last Modified by:   axeldelcros
* @Last Modified time: 2017-02-09 22:00:36
*/
html,
body {
	margin: 0;
	padding: 0;
	font-family: "futura-lt", "futura", "helvetica-neue", helvetica, arial, sans-serif;
	color: #333;
	background-color: #f5f5f5;
	height: 100%;
	text-align: justify;
	-webkit-font-smoothing: subpixel-antialiased;
}

h1,h2,h3,h4,h5 {
	font-family: "rounded-grotesk";
}
/*
h1{
	font-size: 3em;
}*/
h2{
	font-size: 2.5em;
}

.btn{
	margin-right: 10px;
	margin-bottom: 10px;
	padding: 10px 15px;
	background-color:rgba(0,0,0,.1);
	display: inline-block;
	border-radius: 10px;
	text-align: center;
	transition: background-color 0.25s ease, color 0.25s ease;
}

@media all and (min-width: 768px) {
	.btn:hover{
		cursor:pointer;
		background-color: rgba(0,0,0,.2);
	}
}

.btn-center{
	margin:0 auto;
}

.btn.selected{
	background-color: rgba(0,0,0,.6);
	color: #EEE;
}

.pointer:hover{
	cursor: pointer;
}

.logocolor,.logoblack{
	opacity: 1;
	-webkit-transition: opacity 300ms;
	transition: opacity 300ms;
}

.hide{
	opacity: 0;
	-webkit-transition: opacity 300ms;
	transition: opacity 300ms;
}



ul{
	-webkit-padding-start:0;
	padding: 0;
}

li{
	list-style: none;
	margin-left:10px;
}

.noMarginLi {
	margin-left:0;
}

a{
	text-decoration:none;
	color:#555;
}
.red {
	  color: #CD4442;
}

.lightred {
	color: #FA4343;
}

.blue {
	color: #1686b8;
}
.grey {
	color: #888;
}
.green {
	color: #0b7140;
}
.footer {
	font-size: 0.8em;
}
.dark{
	background-color: #eee;
	color:#555;
}
.center{
	text-align: center;
}
.small {
	font-size: 0.8em;
}
.pad-text{
	padding: 0 30px;
}
.block-content{
	margin-top: 75px;
}
.btn:hover{
	cursor:pointer;
}

.btn-center{
	margin:0 auto;
}
.pointer:hover{
	cursor: pointer;
}

.itemsection:hover{
	border-radius:10px;
	box-shadow: 0 0 5px #888;
}

.viewMoreItems {
	display: block;
	text-align: center;
	margin-bottom: 60px;
}

.btn-viewMoreItems {
	color:white;
	background-color: #FA4343;
}

.hidden {
	
	opacity: 0;
	height: 0px;
	overflow: hidden;
}

.zone {
	transition: opacity 1s ease;
}

.zoneHidden {
	opacity: 0;
	height: 0px;
	overflow: hidden;
}

.centeredBlockDiv {
	display: block;
	text-align: center;
	margin-top: 60px;
	margin-bottom: 60px;
}