.jobItem {
    text-align: center;
    padding: 10px 15px;
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 0.7em;
    width: 300px;
}

.jobItem:hover {
    box-shadow: 0 0 5px #888;
    cursor: pointer;
    text-decoration: none;
}
    
.jobItemTitle {
    font-size: 1.5em;
}

.jobItemInfo {
    font-size: 16px;
    display: flex;
    justify-content: center;
    gap: 15px;
    color: #888;
}

@media (max-width: 380px) {
    .jobItem {
        width: 200px;
    }
    .jobItemTitle {
        font-size: 1.3em;
    }
    .jobItemInfo {
        font-size: 12px;
    }
}