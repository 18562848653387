.param{
    position:absolute;
    top:10px;
    left:10px;
    width: 980px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.param p{
    font-size: 25px;
}
.profile{
    position:absolute;
    top:675px;
    left:10px;
    width: 980px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.profile p{
    font-size: 25px;
}

.scoreResult{
    position: absolute;
    width: 1000px;
    height: 720px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.scoreResult p{
    margin: 15px;
    font-size: 40px;
}


.scoreResult img{
    margin: 15px;
}

.quit{
    cursor: pointer;
    background: black;
    color: white;
    width: 100px;
    font-size: 20px;
    text-align: center;
}