
.menu{
    height:719px;
    width: 1000px;
    z-index: 5;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image :url(../../asset/image/sky.jpg);
    background-size: 100%;
}

.textContainer{
    background-color: #cd4442;
    width: 300px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70px;
    cursor: pointer;
    margin: 50px;
}

.textContainer p{
    font-size: 20px;
    color: white;
}

.textContainer:hover p{
    color:black;
    
}

.rules{
    margin: 0;
    height:720px;
    width: 1000px;
    z-index: 5;
    display:flex;
    flex-direction: column;
    align-items: center;
    background-image :url(../../asset/image/sky.jpg);
    background-size: 100%;
}
.first{
    font-size: 20px;
    color: black;
    margin:0px;
    margin-top: 50px;
    margin-right: 30px;
    margin-left: 30Px; 
}

.bonusRule{
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    margin-right: 60px;
    margin-left: 60Px;
    margin-top: 20px;
    width: 880px;
}
.bonusRule p{
    margin-left: 40px;
    font-size: 20px;
    color: black;
}

.bonusBorder{
    height: 15px;
    width: 15px;
    border: solid;
}

.exit{
    cursor: pointer;
    height: 40px;
    width: 40px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.selectionParam{
    position:absolute;
    top:0;
    left:0;
    width: 1000px;
    height: 720px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.selectionParam h1{
    font-size: 30px;
    margin-top:30px;
    margin-bottom: 40px;
}
.selectionParam p{
    font-size: 20px;
    margin:0;
    margin-bottom: 15px;
}
.choose{
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
    margin-bottom: 80px;
}
.moins{
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 40px solid;
    
}
.backgroundSelect{
    border:solid;
    height: 160px;
    width:160px;
    margin-left:30px;
    margin-right: 30px;
    background-color: white;
}
.plus{

    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 40px solid;

}
