.rw-multiselect {
    font-size: 16px !important;
    width: calc(100% - 15.5px) !important;
    margin-bottom: 30px;
    font-family: 'futura-lt','futura','Helvetica Neue','Helvetica','Arial','sans-serif' !important;
}

.rw-multiselect-tag {
    font-size: 16px !important;
    margin-bottom: 10px;
}

.rw-list-option {
    color: #888 !important;
}

.rw-list-option.rw-state-focus{
    border: none;
}

.rw-popup {
    background-color: #efefef !important;
}

.rw-widget-input {
    border: none !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    font-size: 18px!important;
}

.rw-widget-container {
    border-radius: 5px !important;
    box-shadow: none !important;
}
