/*
* @Author: axeldelcros
* @Date:   2017-02-07 22:53:18
* @Last Modified by:   axeldelcros
* @Last Modified time: 2017-02-09 22:01:00
*/

@keyframes fade-in {
	from { 
	  opacity: 0; 
	}
	to { 
	  opacity: 1; 
	}
  }
  
  @keyframes fade-out {
	from { 
	  opacity: 1; 
	}
	to { 
	  opacity: 0;
	}
  }
.inlined-inputs {
	display: inline-block;
	color: #888;
	font-style: italic;
	font-size: 18px;
}

.visible {
	animation: fade-in 1s;
	opacity: 1;
}

.hidden {
	animation: fade-out 1s;
	opacity: 0
}

.checkboxes {
	text-align: center;
}

.contact-form-error	{
	display:none;
	width: 100%;
	height: 70px;
	font-size: 20px;
	background-color: #FA4343;
	vertical-align: middle;
	text-align: center;
	border-radius: 5px;
	line-height: 2;
}

input:focus {
	outline:none;
}

button {
	border:none;
	font-size: 18px;
	font-family: inherit;
}

.sendbtn {
	color:white;
	background-color: #FA4343;
}

.blackbtn {
	color: white;
	background-color: black;
}

.bluebtn {
	color: white;
	background-color: #1686b8;
}

.darkbluebtn {
	color: white;
	background-color: #1b1464;
}

.orangebtn {
	color: white;
	background-color: #f1733a;
}

.greenbtn {
	color: white;
	background-color: #4f773a;	
}

form .buttons {
	float: left;
}

form .resetbtn {
	margin-right: 6px;
}

form .sendbtn {
	float: right;
	margin-right: 30px;
}

form .agreement {
	margin-bottom: 5%;
}

.group { 
	position:relative; 
	margin-bottom:30px; 
}

div.check {
	text-align: center;
	width:calc(100% - 30px);
}

.input{
	position: relative;
	font-size:18px!important;
	padding:10px 10px 10px 5px;
	font-family:'futura-lt','futura','Helvetica Neue','Helvetica',Arial,sans-serif;
	display:block;
	width:calc(100% - 30px);
	border:none;
	background-color: rgba(0,0,0,.04);
	border-radius: 5px;
	box-shadow: none;
	resize: none;
	z-index:3;
}

input.checkbox {
	width: 20px;
	display : inline-block;
}

textarea{
	overflow: auto;
	min-height: 200px
}

textarea:focus{
	outline:none;
}

.labelclass{
	z-index:2;
	position:absolute;
	color:#888; 
	font-size:16px;
	font-weight:normal;
	pointer-events:none;
	left:5px;
	top:10px;
	font-style: italic;
	transition:0.2s ease all; 
	-moz-transition:0.2s ease all; 
	-webkit-transition:0.2s ease all;
}

/* IE9 doesn't allow multiple selector with siblings selection... */
input:focus ~ .labelclass{
	color: #CD4442;
	font-size:14px;
	top:-20px;
	font-style: normal;
}
input:valid ~ .labelclass{
	color: #CD4442;
	font-size:14px;
	top:-20px;
	font-style: normal;
}

.labelclass-active{
	color: #CD4442;
	font-size:14px;
	top:-20px;
	font-style: normal;
}

textarea:focus ~ .labelclass{
	color: #CD4442;
	font-size:14px;
	top:-20px;
	font-style: normal;
}
textarea:valid ~ .labelclass{
	color: #CD4442;
	font-size:14px;
	top:-20px;
	font-style: normal;
}
/*Modif */
.textarea-valid ~ .labelclass{
	color: #CD4442;
	font-size:14px;
	top:-20px;
	font-style: normal;
}

.rw-multiselect-tag ~ .labelclass {
	color: #CD4442;
	font-size:14px;
	top:-50px;
	font-style: normal;
}
.rw-open ~ .labelclass {
	color: #CD4442;
	font-size:14px;
	top:-20px;
	font-style: normal;
}

/* BOTTOM BARS ================================= */
.bar 	{ position:relative; display:block; width:103%; background-color:#FA4343;}
.bar:before, .bar:after, .textareabar:before, .textareabar:after 	{
	content:'';
	height:2px; 
	width:0;
	bottom:1px;
	position:absolute;
	background:#FA4343; 
	transition:0.2s ease all; 
	-moz-transition:0.2s ease all; 
	-webkit-transition:0.2s ease all;
}
.bar:before {
	left:32%;
}

.textareabar:before {
	left:34.5%;
}
.bar:after,.textareabar:after {
	right:50%; 
}

/* active state */
input:focus ~ .bar:before, input:focus ~ .bar:after, textarea:focus ~ .textareabar:before, textarea:focus ~ .textareabar:after {
	width:50%;
}
.bar-active:before, .bar-active:after {
	width:50%;
}

/* TEMPORARY STORED LIGHTGRID EFFECTS */
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill{background-color: blue;}
.intern-padding{padding: 0 40px;}


/* Toaster */

/* Prise en compte du header (80px) */
.Toastify__toast-container--top-center {
    padding-top: 100px;
}

/* Ajout de marges sur les toasts en mobile */
@media all and (max-width: 480px) {
    .Toastify__toast-container--top-center {
        padding-left: 20px;
        padding-right: 20px;
    }

    .Toastify__toast {
        margin-bottom: 10px;
    }
}


/* Captcha */

.captcha {
    position: relative;
    display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
    width: calc(100% - 15px);
    margin: auto;
	margin-bottom: 20px;
}
.captcha-image {
	max-height: 50px;
	max-width: 200px;
}
.captcha-input {
	margin: 0 10px;
	width: 200px;
}

@media all and (max-width: 767px) {
    .captcha {
		flex-direction: column;
    }
	.captcha-input {
		margin: 10px 0 0 15px;
	}
}
